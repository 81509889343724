// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap");

/* For use in src/lib/core/theming/_palette.scss */
$md-mcgpalette-primary: (
  50: #e9edfc,
  100: #c8d1f8,
  200: #a4b3f4,
  300: #7f95f0,
  400: #637eec,
  500: #4867e9,
  600: #415fe6,
  700: #3854e3,
  800: #304adf,
  900: #2139d9,
  A100: #ffffff,
  A200: #dfe2ff,
  A400: #acb5ff,
  A700: #929eff,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-mcgpalette-secondary: (
  50: #fcebfa,
  100: #6c7f84,
  200: #536a70,
  300: #3a555b,
  400: #224047,
  500: #0a2b33,
  600: #09262d,
  700: #09262d,
  800: #071e23,
  900: #06191e,
  A100: #ffffff,
  A200: #b5bfc1,
  A400: #9daaad,
  A700: #849599,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$bayone-telecom-primary: mat.define-palette($md-mcgpalette-primary);
// $bayone-telecom-primary: mat.define-palette(mat.$indigo-palette);
$bayone-telecom-accent: mat.define-palette($md-mcgpalette-secondary);
// $bayone-telecom-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$bayone-telecom-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$bayone-telecom-theme: mat.define-light-theme((color: (primary: $bayone-telecom-primary,
        accent: $bayone-telecom-accent,
        warn: $bayone-telecom-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bayone-telecom-theme);
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Sarabun", sans-serif;
  font-family: "Source Sans Pro", sans-serif;
  background: #f5f5fd;
}

app-header {
  // position: fixed;
  width: 100%;
  // top: 0;
  z-index: 999;
}

.section-title {
  color: #242424;
  font-size: 18px;
  background: #0a2b33;
  color: #fff;
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
  padding: 10px 10px 10px 20px;
  display: flex;
  width: auto;
  margin: 0;
  border-radius: 10px 10px 0px 0px;
}

.d-flex {
  display: flex;
}

.br {
  border: 1px solid #e6e6e6;
}

.br-l {
  border-left: 1px solid #e6e6e6;
}

.br-r {
  border-right: 1px solid #e6e6e6;
}

.br-t {
  border-top: 1px solid #e6e6e6;
}

.br-b {
  border-bottom: 1px solid #e6e6e6;
}

.br-0 {
  border: 0;
}

.ms-0 {
  margin-left: 0;
}

.me-0 {
  margin-right: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ms-auto {
  margin-left: auto;
}

.me-auto {
  margin-right: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.col {
  flex: 1 0 0%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.col-sm-12 {
  flex: 0 0 auto;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
  color: #242424;
}

h2 {
  font-size: 18px;
}

.red-text {
  color: #ff3232;
}

.yellow-text {
  color: #ffa32a;
}

.blue-text {
  color: #0e79a7;
}

.green-text {
  color: #35983d;
}

.orange-text {
  color: #ee4f4f;
}

.mdc-button__label {
  font-weight: 200;
}

.material-symbols-rounded {
  font-variation-settings: "FILL" 0, "wght" 200, "GRAD" 200, "opsz" 48;
  font-size: 28px;
}

button.mdc-button {
  padding: 22px !important;
  font-family: "Poppins", sans-serif;
  border-radius: 8px !important;
}

button.mat-primary {
  background: #92A650 !important;
  background: #3F79AA !important;

  &:hover {
    background-color: #0a2b33 !important;
  }
}

button.mat-mdc-outlined-button:not(:disabled) {
  color: #38388e;
  border-color: #38388e !important;
}

.cust-tabs {
  .mat-mdc-tab-group {
    .mat-mdc-tab-header {
      &:before {
        content: "";
        border-bottom: 2px solid #e6e6e6;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.cust-dialog {
  .mdc-dialog {
    .mat-mdc-dialog-content {
      padding-top: 15px !important;
      width: 400px;

      .mat-mdc-form-field {
        width: 100%;
      }
    }

    .mat-mdc-dialog-actions {
      padding: 15px;
    }
  }
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #0a2b33 !important;
  font-weight: 400 !important;
  display: flex;
}

.mdc-tab__text-label {
  display: flex;
  gap: 10px;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #0a2b33 !important;
}

.mat-mdc-form-field {
  .mat-mdc-text-field-wrapper {}

  .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #0a2b33 !important;
  }

  &.chip-list {
    .mat-mdc-text-field-wrapper {
      &:not(.mdc-text-field--outlined) {
        .mat-mdc-form-field-infix {
          padding-top: 19px;
          padding-bottom: 8px;
          min-height: 44px !important;
          max-height: fit-content !important;
        }
      }
    }
  }
}

table.app-theme-table {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  border-spacing: 0 !important;
  overflow: hidden;
  width: 100%;
  box-shadow: none !important;

  tr {

    th,
    td {
      padding: 8px 15px;
      text-align: left;
      font-weight: 400;

      [class*="material-symbols-"] {
        font-size: 20px;
        vertical-align: middle;
      }
    }
  }

  thead {
    background-color: #f8f8f8;

    tr {
      th {
        border: 0;
        color: #686868;
      }
    }
  }

  tbody {
    tr {
      td {
        border: 0;
        border-top: 1px solid #e6e6e6;
        color: #242424;
      }
    }
  }
}

.gm-style-cc {
  display: none;
}

.gm-bundled-control .gmnoprint {
  display: block;
}

.gmnoprint:not(.gm-bundled-control) {
  display: none;
}

.gm-style-cc {
  display: none !important;
}

.gm-style a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.align-center {
  display: flex;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

/************************/
.form-input-wrapper {
  margin-bottom: 15px;
}

.blue-snackbar {
  background: #2196f3;
}

.red {
  color: red;
}

.no-data-table {
  text-align: center;
  padding: 20px;
  border: 2px dashed #ddd;
  font-size: 18px;
  margin: 10px;
}

.full-width {
  width: 100%;
}

.no-data-found {
  border: 2px dashed #ddd;
  padding: 20px 10px;
  text-align: center;
  width: 100%;
  width: 100%;
  font-size: 18px;
  margin: 10px;
  width: 97%;
}

.bold {
  font-weight: bold;
}

.back-btn {
  cursor: pointer;
}

::ng-deep .mat-mdc-tab .mdc-tab__text-label {
  display: inline-flex;
  align-items: center;
  display: flex !important;
  align-items: center;
}

::nd-deep .logoBar .logo .text {
  padding: 0;
  display: none !important;
  cursor: pointer;
  font-family: 'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif;
}

#powerBiReport .report-footer .report-author,
#powerBiReport .report-footer .social-buttons {
  display: none !important;
}

.mat-mdc-raised-button:disabled {
  color: #fff !important;
  background: #ada7dd !important;
  cursor: not-allowed !important;
}

::ng-deep .mat-icon {
  height: 32px !important;
  width: 32px !important;
  font-size: 32px !important;
}

::ng-deep .mat-mdc-form-field {
  width: 300px !important;
}

::ng-deep .material-icons {
  font-size: 32px !important;
}

/*
 *  STYLE 3
 */

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #0a2b33;
  border-radius: 10px;              
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #0a2b33;
  border-radius: 10px;
}

/*
  *  STYLE 4
  */
.sidebar-collapsed {
  .members-details-main-wrapper .tickets-wrapper {
    flex: 1;
    min-width: 25%;
    position: fixed;
    background: #fff;
    height: calc(100% - 120px);
    left: 130px !important;
    transition: all 0.5s ease 0s;
    top: 110px;
    box-shadow: 0px 0px 15px 2px rgba(110, 109, 109, 0.7);
  }
}
